<template>
  <div coach-filter-champ>
    <TextInput v-model="keyword" placeholder="챔피언을 선택하세요" class="input-champ-keyword" />
    <div class="champ-list">
      <div class="champ-item-skeleton" v-for="i in 10" :key="`champ-skeleton-item-${i}`" v-show="loadState !== 'success'">
        <div class="skeleton-icon" />
        <div class="skeleton-text" />
      </div>
      <div
        class="champ-item"
        v-for="champ in champs"
        :key="`coach-filter-item-${champ.code}`"
        :aria-checked="isMulti ? champIdList.includes(champ.code) : champ.code === value"
        @click="() => onChampClick(champ.code)"
      >
        <cdn-img :src="champ.imageUrl" d="64x64" class="champ-icon" />
        <span>{{ champ.koName }}</span>
        <img src="/img/coaching/champ-filter-select-check.svg" class="check-icon"
          v-show="isMulti ? champIdList.includes(champ.code) : champ.code === value" alt="selected(check)" />
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import CdnImg from '@shared/components/common/CdnImg.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';

export default {
  components: { TextInput, CdnImg },
  data: () => (
    {
      keyword: '',
      champs: /** @type {LolChamp[]} */ [],
      loadState: /** @type {LoadState} */ 'init',
    }),
  props: {
    getChamps: {
      type: Function,
      // STORE_LOL 참조
      // (keyword: string) => Promise<LolChamp[]>
      default: async () => [],
    },
    // 선택한 챔프 id
    value: {
      type: String,
      default: '',
    },
    // 챔프 다중 선택 가능 여부
    isMulti: {
      type: Boolean,
      default: false,
    },
    // 선책한 챔프 id List (챔프 다중 선택 가능 시 사용)
    champIdList: {
      type: Array,
      default: () => [],
    },
    champMaxCount: {
      type: Number,
      default: 3,
    },
  },
  watch: {
    keyword() {
      this.getChampsDebounced();
    },
  },
  methods: {
    async _getChamps() {
      this.loadState = 'loading';
      try {
        this.champs = await this.getChamps(this.keyword);
        this.loadState = 'success';
      } catch (err) {
        this.loadState = 'fail';
      }
    },
    getChampsDebounced: _debounce(
      function () {
        this._getChamps();
      },
      300,
      { trailing: true },
    ),
    onChampClick(champId) {
      if (this.isMulti) {
        if (this.champIdList.includes(champId)) {
          this.champIdList.splice(this.champIdList.indexOf(champId), 1);
        } else if (this.champIdList.length < this.champMaxCount) {
          this.champIdList.push(champId);
        }
        this.$emit('selectChamp', this.champIdList);
      } else {
        this.$emit('input', champId);
      }
    },
  },
  beforeMount() {
    this._getChamps();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-filter-champ] {.noto;
  .input-champ-keyword {.mb(11);}
  .champ-list { .h(336);.br(12);overflow-y: scroll;box-sizing: border-box;border: solid 1px #d5d5de;}
  .champ-item { .h(56);.flex;.flex-ai(center);.p(12);box-sizing: border-box;
    > .champ-icon {.wh(32, 32);.br(4);.mr(8);border: 1px solid #fff;box-sizing: border-box;}
    > span { flex-grow: 1;.fs(14);.c(@c-title-black);}
    > .check-icon { .w(24); }
    &:hover { .bgc(rgba(164, 163, 174, 0.1));}
    &[aria-checked='true'] { .bgc(rgba(164, 163, 174, 0.3)); }
  ;}
  &:not(:hover):not([aria-checked='true']) {.bgc(#fff);}
  .champ-item-skeleton {
    background-color: #fff;
    height: 56px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    flex-direction: row;
    > .skeleton-icon { .skeleton-bg;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin-right: 8px;
    }
    > .skeleton-text { .skeleton-bg(40);
      width: 46px;
      height: 14px;
      border-radius: 4px;
    }
  }
}
</style>
