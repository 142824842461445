<template>
  <modal-filter-layout modal-filter-champ @close="onClose" @save="onSave" @reset="onReset">
    <template #title>
      <span class="title">특화 챔피언</span>
      <span class="subtitle">(최대 {{ champMaxCount }}개 선택 가능)</span>
    </template>
    <template>
      <filter-champ :get-champs="getChamps" :is-multi="true" :champIdList="champIdList" :champMaxCount="champMaxCount" @selectChamp="champIdList = $event" />
    </template>
  </modal-filter-layout>
</template>

<script>
import FilterChamp from '@/views/components/coaching/filter/FilterChamp.vue';
import ModalFilterLayout from '@/views/components/coaching/filter/ModalFilterLayout.vue';

export default {
  components: { ModalFilterLayout, FilterChamp },
  props: {
    options: { type: Object, default: () => ({ defaultChampIdList: [] }) },
    champMaxCount: {
      type: Number,
      default: 3,
    },
  },
  data: () => ({
    champIdList: [],
  }),
  methods: {
    getChamps(keyword) {
      return this.$services.coaching.getLolChamps({ name: keyword });
    },
    onClose() {
      this.$emit('reject', 'no_champ');
    },
    onReset() {
      this.$emit('resolve', []);
    },
    onSave() {
      this.$emit('resolve', this.champIdList);
    },
  },
  beforeMount() {
    if (this.options?.defaultChampIdList?.length > 0) this.champIdList = this.options?.defaultChampIdList;
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-filter-champ] {
  .champ-list {.h(336)}
  .title {.fs(14, 16); .bold;}
  .subtitle {.fs(12, 16); .c(#aaa)}
  .header {.pt(22) !important;}
}
</style>
