<template>
  <modal-filter-layout modal-filter-champ @close="onClose" @save="onSave" @reset="onReset">
    <template #title-extra>챔피언</template>
    <template>
      <filter-champ :get-champs="getChamps" v-model="champId" />
    </template>
  </modal-filter-layout>
</template>

<script>
import FilterChamp from '@/views/components/coaching/filter/FilterChamp.vue';
import ModalFilterLayout from '@/views/components/coaching/filter/ModalFilterLayout.vue';

export default {
  components: { ModalFilterLayout, FilterChamp },
  props: {
    options: { type: Object, default: () => ({ defaultChampId: '' }) },
  },
  data: () => ({
    champId: '',
  }),
  methods: {
    getChamps(keyword) {
      return this.$services.coaching.getLolChamps({ name: keyword });
    },
    onClose() {
      this.$emit('reject', 'no_champ');
    },
    onReset() {
      this.$emit('resolve', '');
    },
    onSave() {
      this.$emit('resolve', this.champId);
    },
  },
  beforeMount() {
    if (this.options?.defaultChampId) this.champId = this.options?.defaultChampId;
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-filter-champ] {
  .champ-list {.h(336)}
}
</style>
