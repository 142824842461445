<template>
  <Modal modal-filter @close="$emit('close')">
    <template #title>
      <slot name="title">
        <h1>필터</h1>
      </slot>
    </template>
    <template>
      <div class="modal-body">
        <h2>
          <slot name="title-extra"></slot>
        </h2>
        <slot></slot>
        <div class="modal-control">
          <color-button type="light-gray" @click="$emit('reset')" class="btn-reset">
            <svg-refresh />
            <span>초기화</span>
          </color-button>
          <color-button type="dark" @click="$emit('save')">필터 저장</color-button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '@shared/components/common/Modal.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgRefresh from '@/views/graphics/svg-refresh.vue';

export default {
  components: { SvgRefresh, ColorButton, Modal },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[modal-filter] {
  h1, h2 {.bold;.noto;.c(#191919);}
  h1 { .fs(16);.pb(16);.mb(16);border-bottom: solid 1px #ebebeb;}
  h2 { .fs(14);.mb(16); }
  .modal-body { .pb(48); }
  .modal-control { .mt(40);.flex;.flex-ai(center);.flex-jc(space-between);
    [color-button] {.w(50%-5px)}
    .btn-reset { .flex;.flex-ai(center);.flex-jc(center);
      svg * { fill: #191919; }
      svg { .mr(8); }
    }
  }
}
</style>
